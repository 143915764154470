import { Link, useIntl } from "gatsby-plugin-react-intl"
import React from "react"

import LanguageSwitch from "../../components/languageSwitcher/languageSwithch"

import logo from "../../assets/images/logo.png"
import "./header.scss"
const NavItem = ({ label, link }) => {
    return (
        <Link className="navItem" to={link}>
            <span>{label}</span>
        </Link>
    )
}

const PAGES = [
    { label: "BLOG", link: "/blog/" },
    { label: "WORK_WITH_US", link: "/careers/" },
]

export const Header = () => {
    const intl = useIntl()
    return (
        <header>
            <div className={"contained"}>
                <div className={"logoContainer"}>
                    <Link
                        to="/"
                        style={{
                            color: "white",
                            textDecoration: "none",
                        }}
                        className={"logoContainer"}
                    >
                        <img src={logo} alt={"colba-logo"} />
                    </Link>
                </div>
                <div className="navContainer">
                    <nav>
                        {PAGES.map((page, index) => (
                            <NavItem
                                key={index}
                                label={intl.formatMessage({ id: page.label })}
                                link={page.link}
                            />
                        ))}
                    </nav>
                    <span className="separator" />
                    <LanguageSwitch />
                </div>
            </div>
        </header>
    )
}
