import React from "react"
import { Link } from "gatsby"
import "./style.scss"
import { window } from "browser-monads"
const LanguageSwitch = () => {
    const languages = ["en", "es"]

    const getCurrentLanguage = () => {
        const path = window.location.pathname
        return path.split("/")[1]
    }

    const getChangeLanguageHref = (lang) => {
        return window.location.pathname.replace(
            `/${getCurrentLanguage()}/`,
            `/${lang}/`
        )
    }

    return (
        <div
            className={"languageSelector"}
            style={{ textTransform: "uppercase" }}
        >
            <span>
                {getCurrentLanguage()}
                <span className={"caret"}>{">"}</span>
            </span>
            <ul>
                {languages
                    .filter((lang) => lang !== getCurrentLanguage())
                    .map((lang) => {
                        return (
                            <Link key={lang} to={getChangeLanguageHref(lang)}>
                                {lang}
                            </Link>
                        )
                    })}
            </ul>
        </div>
    )
}

export default LanguageSwitch
