import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export const SEO = ({ description, lang, title, meta = [] }) => {
    const { site, file } = useStaticQuery(
        graphql`
            query {
                file(name: { eq: "logo" }) {
                    publicURL
                }
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                    }
                }
            }
        `
    )

    const metaDescription = description || site.siteMetadata.description
    if (!meta.some((field) => field.name === "og:image")) {
        meta.push({
            name: "og:image",
            content: `${site.siteMetadata.siteUrl}${file.publicURL}`,
        })
    }

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: "description",
                    content: metaDescription,
                },
                {
                    property: "og:title",
                    content: title,
                },
                {
                    property: "og:description",
                    content: metaDescription,
                },
                {
                    property: "og:type",
                    content: "website",
                },
                ...meta,
            ]}
        />
    )
}

SEO.defaultProps = {
    lang: "en",
    meta: [],
    description: "",
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}
