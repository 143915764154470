import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Header } from "../header/header"
import "./mainLayout.scss"
export const MainLayout = ({ children, darkMode }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)
    const mainRef = useRef(null)

    const getRandomPosition = () => {
        var y = window.innerWidth - 20
        var x = window.innerHeight - 20
        var randomX = Math.floor(Math.random() * x)
        var randomY = Math.floor(Math.random() * y)
        return [randomX, randomY]
    }

    useEffect(() => {
        if (mainRef.current == null || !darkMode) return
        const numStars = 100

        // For every star we want to display
        for (let i = 0; i < numStars; i++) {
            let star = document.createElement("div")
            star.className = "star"
            var xy = getRandomPosition()
            star.style.top = xy[0] + "px"
            star.style.left = xy[1] + "px"
            mainRef.current.append(star)
        }

        // Gets random x, y values based on the size of the container
    }, [])

    return (
        <>
            <Header siteTitle={data.site.siteMetadata.title} />
            <div>
                <main ref={mainRef} className={!!darkMode ? "dark" : ""}>
                    {children}
                </main>
                <footer className={`mainFooter ${!!darkMode ? "dark" : ""}`}>
                    © Colba Technologies 2020 - Valencia - Spain -{" "}
                    <a className="mail" href="mailto:hello@colba.es">
                        hello@colba.es
                    </a>
                </footer>
            </div>
        </>
    )
}
